<template>
  <div class="pb-3">
    <v-layout row>
      <v-flex xs3>
        <v-text-field
          v-model="search"
          hide-details 
          solo
          label="Search post"
          append-icon="search"
          @input="searchInput"
        />
      </v-flex>
    </v-layout>

    <v-layout row justify-space-between>
      <v-flex xs12>
        <v-btn-toggle class="table-top-menu">
          <v-btn flat large :to="{ name: 'monetization_posts' }">
            All active posts
            <span
              v-if="newPostsCount && newPostsCount.val && newPostsCount.name === 'all'"
              class="count-new-posts"
              v-text="newPostsCount.val"
            >
            </span>
          </v-btn>

          <v-btn flat large :to="{ name: 'monetization_need_approve_posts' }">
            Need to approve
            <span
              v-if="newPostsCount && newPostsCount.val && newPostsCount.name === 'need'"
              class="count-new-posts"
              v-text="newPostsCount.val"
            >
            </span>
          </v-btn>

          <v-btn flat large :to="{ name: 'monetization_archive_posts' }">
            Archive
            <span
              v-if="newPostsCount && newPostsCount.val && newPostsCount.name === 'archive'"
              class="count-new-posts"
              v-text="newPostsCount.val"
            >
            </span>
          </v-btn>

          <v-btn flat large :to="{ name: 'monetization_reported_posts' }">
            Reported
            <span
              v-if="newPostsCount && newPostsCount.val && newPostsCount.name === 'reported'"
              class="count-new-posts"
              v-text="newPostsCount.val"
            >
            </span>
          </v-btn>

          <v-btn flat large :to="{ name: 'monetization_removal_posts' }">
            Removal request
            <span
              v-if="newPostsCount && newPostsCount.val && newPostsCount.name === 'removal'"
              class="count-new-posts"
              v-text="newPostsCount.val"
            >
            </span>
          </v-btn>
        </v-btn-toggle>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "MonPostListTopNav",
  props: {
    newPostsCount: {
      type: [Object],
      default: () => {},
    }
  },
  data: () => ({
    inputSearchPostTimer: null,
    // newPostsCount: null,
    search: null
  }),
  // watch: {
  //   newPostsCount (newVal, oldVal) {
  //     console.log(newVal, oldVal)
  //   }
  // },
  created() {
    console.log(123123, this.newPostsCount)
    // this.$bus.on("new-posts-count", data => {
    //   this.newPostsCount = data
    // })
  },
  destroyed() {
    // this.$bus.off("new-users-count")
  },
  methods: {
    searchInput() {
      clearTimeout(this.inputSearchPostTimer)
      this.inputSearchPostTimer = setTimeout(() => {
        this.$bus.emit("post-search-input", this.search)
      }, 2000)
    }
  }
}
</script>

<style scoped>
.table-top-menu {
  width: 100%;
}

.table-top-menu .v-btn {
  height: 48px;
  flex-grow: 1;
}

.v-btn.v-btn--active {
  pointer-events: none;
}
.count-new-posts {
  position: absolute;
  right: 0;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 2px solid #f44336;
  border-radius: 50%;
  color: #f44336;
  font-size: 12px;
  font-weight: bold;
  transform: translateY(-50%);
}
</style>
