import api from "./index"

/**
 * post approve
 *
 * @param id
 * @return {*}
 */
export const postApprove = id => {
  return api
    .request(`admin/post/approve`)
    .withParams({
      post_id: id
    })
    .put()
}

/**
 * post decline
 *
 * @return {*}
 * @param params
 */
export const postDecline = params => {
  return api
    .request("admin/post/decline")
    .withParams(params)
    .put()
}
/**
 * post restore
 *
 * @param id
 * @return {*}
 */
export const postRestore = id => {
  return api
    .request(`admin/post/restore`)
    .withParams({
      post_id: id
    })
    .put()
}

/**
 * Delete post
 *
 * @param params
 * @return {*}
 */
export const deletePost = params => {
  return api
    .request("admin/post/delete")
    .withParams(params)
    .put()
}

/**
 * change post status to visited
 *
 * @param id
 * @return {*}
 */
export const postChangeVisitedStatus = id => {
  return api
    .request(`admin/post/setNotNew`)
    .withParams({ post_id: id })
    .put()
}

/**
 *  post reports
 *
 * @param id
 * @return {*}
 */
export const postReportsRequest = params => {
  return api
    .request(`admin/post/reported/details`)
    .withParams(params)
    .get()
}

/**
 * Delete post forever
 *
 * @param params
 * @return {*}
 */
export const deleteForeverPost = params => {
    return api
        .request("admin/post/delete-forever")
        .withParams(params)
        .put()
}

/**
 * Delete parsed post forever
 *
 * @param params
 *
 *
 * @return {*}
 */
export const deleteParsedForeverPost = params => {
    return api
        .request(`admin/parser/${params.resource}/post-delete-forever`)
        .withParams(
            {post_id: params.post_id}
        )
        .delete()
}
